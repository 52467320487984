<template>
    <v-card class="mx-3 mt-4">
    <v-date-picker
      v-model="date"
      :events="arrayEvents"
      no-title
      scrollable
      event-color="warning"
      full-width
      locale="zh-cn"
      @click:date="dateClick"
      dense
      color="primary"
      :day-format="dayformat"
    >
    </v-date-picker>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
export default {
  name: 'Calendar',
  data: () => ({
    date: dayjs().format('YYYY-MM-DD'),
  }),
  methods: {
    dateClick(date) {
      this.$emit('calendarClick', date)
    },
    dayformat(date) {
      return dayjs(date).format('D')
    }
  },
  computed: {
    ...mapState({
      arrayEvents: state => state.arrayEvents
    })
  }
}
</script>

<style>
.theme--dark.v-picker__body {
  background: transparent;
}
</style>