<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption">功能</v-subheader>
    <v-container fluid class="pt-0">
      <v-row no-gutters>
        <IconButton :img="bindImgPath" title="绑定学号" :size="32" path="/today/bind"></IconButton>
        <IconButton :img="scheduleImgPath" title="获取课表" :size="32" path="/today/getSchedule"></IconButton>
        <IconButton :img="electricityImgPath" title="获取电费" :size="32" path="/today/getpower"></IconButton>
      </v-row>
      <v-snackbar v-model="snackbar" timeout="1500" top >获取电费功能还在施工中哦:)</v-snackbar>
    </v-container>
  </v-card>
</template>

<script>
import IconButton from 'components/common/IconButton'
import set from 'assets/img/feature/set.svg'
import setDark from 'assets/img/feature/setDark.svg'
import schedule from 'assets/img/feature/schedule.svg'
import scheduleDark from 'assets/img/feature/scheduleDark.svg'
import electricity from 'assets/img/feature/electricity.svg'
import electricityDark from 'assets/img/feature/electricityDark.svg'

export default {
  name: 'FeatureCard',
  components: {
    IconButton
  },
  data: () => ({
    snackbar: false
  }),
  methods: {
    getElectricity() {
      this.snackbar = true
    }
  },
  computed: {
    bindImgPath() {
      return this.isDark ? setDark : set
    },
    scheduleImgPath() {
      return this.isDark ? scheduleDark : schedule
    },
    electricityImgPath() {
      return this.isDark ? electricityDark : electricity
    },
    isDark() {
      return this.$vuetify.theme.dark
    }
  }
}
</script>

<style>

</style>