<template>
  <v-card class="mx-3 mt-4">
    <v-subheader class="text-caption">通知</v-subheader>
      <v-container class="pt-0">
        <v-row justify="center">
          <v-col class="py-0 pl-4">
            <div class="text-caption" v-for="(msg, idx) in msgs" :key="`notify-${idx}`">
              {{ msg }}
            </div>
          </v-col>
        </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'NotifyCard',
  data: () => ({
    msgs: ['欢迎使用「Na+课表」', '初次使用请先绑定学号信息!', '建议以书签的方式保存到桌面以获得原生App的体验']
  })
}
</script>

<style>

</style>